import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'

export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  helmet,
}) => {
  const PostContent = Content

  return (
    <section style={{padding: '3rem 1.5rem'}}>
      {helmet || ''}
      <Container maxWidth='md'>
        <Grid container alignContent='center' alignItems='center'>
        <Grid item xs={12} sm={12} md={12} lg={12}>
            <Typography variant="h3" component='h3' style={{paddingBottom:'32px'}}>
              {title}
            </Typography>

            <Typography color='textSecondary' variant='body1' component='p' style={{paddingBottom:'16px'}} >
              {description}
            </Typography>

            <PostContent content={content} />
            {tags && tags.length ? (
              <div style={{ marginTop: `4rem` }}>
              <Typography variant="h4" component='h4' > Tags </Typography>
                <ul style={{
                  listStyleType: 'none',
                  margin: 0,
                  padding: 0,
                  overflow: 'hidden',
                }}>
                  {tags.map(tag => (
                    <li key={tag + `tag`}  style={{float: 'left'}}>
                      <Link to={`/tags/${kebabCase(tag)}/`}
                       style={{  
                          display: 'block',
                          textAlign: 'center',
                          padding: '16px',
                          textDecoration: 'none',}}
                      >
                      {tag}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </Grid>
          </Grid>

      </Container>
    </section>
  )
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      rawMarkdownBody
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        tags
      }
    }
  }
`
